﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import type { IMoneyTransferState } from '@/models/stores/moneyTransfer'
import helpers from '../exports/helper'
import { useCommonStore } from './CommonStore'
import { useAccountSettingsStore } from './AccountSettingsStore'
import httpClient from '@/httpClient'
import {
  ActivityType,
  CustomerStatus,
  CustomerVerificationStatus,
  FundingSourceMicroDepositStatus,
  FundingSourceStatus,
  FundingSourceType,
  ScheduledPaymentStatus,
  RequestStatus,
  TosType,
  ErrorType,
  CreateFundingSourceErrorType,
  MicroDepositErrorType
} from '@/pages/moneyTransfer/MTConstants'
import mtHelpers from '@/pages/moneyTransfer/moneyTransferHelpers'
import type {
  IActivityListItem,
  IDateTimeSettings,
  IFundingSource,
  INotification,
  IPagedPayload,
  IPagedSearchPayload,
  IPayment,
  IRemoveFundingSourceRequest,
  IScheduledPayment,
  ITransferRequest,
  IUpdateScheduledPaymentRequest,
  ISearchData,
  IActivityByIdPayload,
  IFetchActivityPagedPayload,
  IFetchActivityByIdPayload,
  IUpdateFundingSourceAfterUpdateRequest
} from '@/models/interfaces'
import type {
  IGetMoneyTransferResponse,
  IGetMoneyTransferFundingSourceResponse,
  IGetMoneyTransferScheduledPaymentResponse,
  ICreateMoneyTransferRequest,
  IGetPaymentTotalResponse,
  ICreateMoneyTransferFundingSourceUsingPlaidRequest,
  ICreateMoneyTransferPlaidErrorLogRequest,
  IVerifyMicroDepositRequest,
  IUpdateDefaultDestinationFundingSourceRequest,
  ICreateTransferToWalletRequest,
  ICreateMoneyTransferScheduledPaymentRequest,
  IUpdateMoneyTransferScheduledPaymentStatus,
  ICreateMoneyTransferRequestRequest,
  IUpdateMoneyTransferRequestRequest,
  IIdRequest,
  ICreateCustomerRequest,
  IMoneyTransferCustomerRetryRequest,
  IGetFundingSourceDependenciesResponse,
  IGetCoparentAccountStateResponse
} from '@/models/models'
import moment from 'moment-timezone'
import i18n from '@/i18n'

const initialState = (): IMoneyTransferState => ({
  customer: null,
  customerInit: false,
  coparentAccountState: null as unknown as IGetCoparentAccountStateResponse,
  coparentAccountStateInit: false,
  fundingSources: [] as unknown as IFundingSource[],
  fundingSourcesInit: false,
  selectedPaymentFundingSource: null as unknown as IFundingSource,
  selectedRequestFundingSource: null as unknown as IFundingSource,
  upcomingScheduledPayments: [],
  activityList: [] as IActivityListItem[],
  activityListInit: false,
  loadMoreActivity: false,
  activityListCount: 0,
  currentActivity: null,
  linkToken: null,
  linkError: null,
  linkTokenForMicrodeposit: null,
  linkTokenForUpdateMode: null,
  paymentTotal: {},
  fundingSourceDependencies: null,
  verifyCustomerError: null,
  verifyDocumentUploadError: null,
  createTransferError: null,
  createTransferToWalletError: null, //leaving this in here as we eventually want wallet transfers
  updateRequestError: null,
  removeFundingSourceError: null,
  createFundingSourceError: null,
  verifyFundingSourceMicroDepositError: null,
  verifyFundingSourceMicroDepositValidationError: null,
  verifyFundingSourceMicroDepositFailedAttemptError: null,
  verifyMicroDepositFundingSource: null as unknown as IFundingSource | null,
  verifyMicroDepositPlaidFundingSource: null,
  notifyCoparentLastSent: null,
  notifyCoparentLastSentInit: false,
  requstReminderLastSent: null,
  tosAccepted: false,
  tosId: null,
  transferFees: null,
  moneyTransferDataLoaded: false,
  mtNotification: {
    type: 'warning',
    text: '',
    showCloseButton: false,
    showChevronButton: false
  },
  showMtNotification: false,
  searchData: null as unknown as ISearchData,
  pageSize: 25,
  toUpgradeFundingSourceId: 0,
  reverifyPlaidFundingSource: null,
  redirecting: false,
  newTransferRequest: false
})

const paths: string[] = []

export const useMoneyTransferStore = defineStore('moneyTransfer', {
  state: initialState,
  getters: {
    getFormattedBalance: (state) => {
      const _balance = state.customer?.balance?.currentBalance?.value || 0
      if (_balance == 0) {
        return '$0.00'
      } else if (_balance < 0) {
        return '- $' + Math.abs(_balance)
      } else {
        return '$' + mtHelpers.formatMoney(_balance.toString())
      }
    },
    customerStatus: (state) => {
      if (state.customer == null || state.customer.status == undefined)
        return CustomerStatus.UNVERIFIED
      return state.customer.status
    },
    isCustomerPendingInternalVerification: (state) => {
      return (
        state.customer?.verificationStatus ==
        CustomerVerificationStatus.PendingInternalVerification
      )
    },
    isCustomerInternalVerificationFailed: (state) => {
      return (
        state.customer?.verificationStatus == CustomerVerificationStatus.Failed
      )
    },
    isCustomerInternallyVerified: (state) => {
      return (
        state.customer?.verificationStatus ==
        CustomerVerificationStatus.Verified
      )
    },
    defaultFundingSource: (state) => {
      return state.fundingSources.find(
        (f) =>
          !f.removed &&
          f.defaultDestination &&
          f.status != FundingSourceType.WALLET
      )
    },
    coparentCustomerStatus: (state) => {
      if (state.coparentAccountState == null) return CustomerStatus.UNVERIFIED
      return state.coparentAccountState.status
    },
    isCustomerVerified(): boolean {
      return this.customerStatus == CustomerStatus.VERIFIED
    },
    isCustomerDisabled(): boolean {
      return (
        this.customerStatus == CustomerStatus.SUSPENDED ||
        this.customerStatus == CustomerStatus.DEACTIVATED
      )
    },
    isCoparentCustomerVerified(): boolean {
      return this.coparentCustomerStatus == CustomerStatus.VERIFIED
    },
    isCoparentCustomerDisabled(): boolean {
      return (
        this.coparentCustomerStatus == CustomerStatus.SUSPENDED ||
        this.coparentCustomerStatus == CustomerStatus.DEACTIVATED
      )
    },
    hasNegativeBalance: (state) => {
      return (
        state.customer &&
        ((state.customer?.balance?.currentBalance?.value &&
          state.customer?.balance?.currentBalance?.value < 0) ||
          (state.customer.balance?.negativeBalance &&
            state.customer.balance.negativeBalance.lockCustomer))
      )
    },
    coparentHasNegativeBalance: (state) => {
      return state.coparentAccountState.hasNegativeBalance
    },
    hasFundingSource: (state) => {
      return state.fundingSources.some(function (item) {
        return (
          (item.externalId || item.avStatus) &&
          item.type != FundingSourceType.WALLET
        )
      })
    },
    hasMicroDepositToBeVerified: (state) => {
      return state.fundingSources.some(function (item) {
        return (
          //item.externalId &&
          item.removed == false &&
          item.status == FundingSourceStatus.UNVERIFIED &&
          item.microDepositStatus ==
            FundingSourceMicroDepositStatus.PROCESSED &&
          (!item.microDepositVerifyAttempts ||
            item.microDepositVerifyAttempts < 3)
        )
      })
    },
    isCoparentFundingSourceValid: (state) => {
      return state.coparentAccountState == null
        ? true
        : state.coparentAccountState.hasValidFundingSource
    },
    isCoparentDefaultFundingSourceRemoved: (state) => {
      return (
        state.coparentAccountState != null &&
        !state.coparentAccountState.hasVerifiedFundingSource &&
        !state.coparentAccountState.hasValidFundingSource &&
        state.coparentAccountState.status != null
      )
    },
    isFundingSourceUnverified: (state) => {
      return state.fundingSources.some(function (item) {
        return (
          item.externalId &&
          item.removed == false &&
          item.status == FundingSourceStatus.UNVERIFIED &&
          item.microDepositStatus == null &&
          item.type != FundingSourceType.WALLET
        )
      })
    },
    isDefaultFundingSourceRemoved(): boolean {
      const defaultFundingSource = this.defaultFundingSource
      return (
        (defaultFundingSource == null ||
          defaultFundingSource.removed == true) &&
        !this.isDefaultFundingSourceUnverified
      )
    },
    isDefaultFundingSourceUnverified(): boolean {
      const defaultFundingSource = this.defaultFundingSource
      return (
        defaultFundingSource == null ||
        defaultFundingSource.status == FundingSourceStatus.UNVERIFIED
      )
    },
    getUserHasFundingSourceError(): string | null {
      if (!this.moneyTransferDataLoaded) return null
      if (this.isDefaultFundingSourceRemoved) {
        return i18n.global.t(
          'payments.activityList.bankAccountAddToContinueNotification'
        )
      }

      if (this.isDefaultFundingSourceUnverified) {
        return i18n.global.t(
          'payments.activityList.bankAccountAddToContinueNotification'
        )
      }

      if (this.isFundingSourceUnverified) {
        return i18n.global.t(
          'payments.activityList.bankAccountIssueNotification'
        )
      }

      return null
    },
    canMakePayments: (state) => {
      return state.customer != null && state.customer.canMakePayments
    },
    isReadOnly(): boolean {
      return (
        this.isDefaultFundingSourceUnverified ||
        (this.hasActivity &&
          this.isCoparentCustomerVerified &&
          !this.isCoparentFundingSourceValid) ||
        this.isCoparentDefaultFundingSourceRemoved
      )
    },
    hasActivity: (state) => !!state.activityList.length,
    hasPaymentTotal: (state) => {
      return Object.keys(state.paymentTotal).length > 0
    },
    notificationSent: (state) => {
      return (
        state.notifyCoparentLastSent != null &&
        moment() < moment(state.notifyCoparentLastSent)
      )
    },
    requestReminderSent: (state) => {
      return (
        state.requstReminderLastSent != null &&
        moment() < moment(state.requstReminderLastSent)
      )
    },
    enabledFundingSources(state) {
      return state.fundingSources.filter(
        (f) =>
          f.removedFromThirdPartySystem == false &&
          f.type != FundingSourceType.WALLET
      )
    },
    isScheduledPaymentCanceled: () => (scheduledPaymentStatus: number) => {
      return (
        scheduledPaymentStatus == ScheduledPaymentStatus.CANCELED ||
        scheduledPaymentStatus ==
          ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER ||
        scheduledPaymentStatus ==
          ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE ||
        scheduledPaymentStatus ==
          ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED ||
        scheduledPaymentStatus ==
          ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED ||
        scheduledPaymentStatus ==
          ScheduledPaymentStatus.SYSTEM_CANCELED_FAILED_PAYMENT
      )
    },
    hasPaymentDependencies: (state) => {
      return (
        state.fundingSourceDependencies != null &&
        state.fundingSourceDependencies.pendingTransfers != null &&
        state.fundingSourceDependencies.pendingTransfers.length > 0
      )
    },
    hasRequestDependencies: (state) => {
      return (
        state.fundingSourceDependencies != null &&
        state.fundingSourceDependencies.pendingTransferRequests != null &&
        state.fundingSourceDependencies.pendingTransferRequests.length > 0
      )
    },
    hasScheduledPaymentDependencies: (state) => {
      return (
        state.fundingSourceDependencies != null &&
        state.fundingSourceDependencies.pendingScheduledPayments != null &&
        state.fundingSourceDependencies.pendingScheduledPayments.length > 0
      )
    },
    hasDependencies(): boolean {
      return (
        this.hasPaymentDependencies ||
        this.hasRequestDependencies ||
        this.hasScheduledPaymentDependencies
      )
    },
    getEnabledFundingSourceDictionary(): Map<string, number> {
      const fundingSourceMap = new Map<string, number>()

      this.enabledFundingSources.forEach((fs) => {
        if (fs.status == FundingSourceStatus.VERIFIED) {
          const expressString = fs.hasExpressPay ? ' (Express)' : ''
          fundingSourceMap.set(fs.name + expressString || '', fs.id || 0)
        }
      })

      return fundingSourceMap
    },
    getRedirectPage(): string {
      // order matters
      const commonStore = useCommonStore()
      // Intro
      if (
        !commonStore.welcomePagesViewed.moneyTransfer &&
        this.customerStatus == CustomerStatus.UNVERIFIED
      ) {
        return 'intro'
      }
      // MFA Disabled
      if (!commonStore.fullUserInfo.mfaSmsEnabled) {
        return 'mfaDisabled'
      }
      // Negative Balance
      if (
        !this.isCustomerDisabled &&
        (this.hasNegativeBalance || this.coparentHasNegativeBalance)
      ) {
        return 'negativeBalance'
      }
      // Pending External Verification
      if (
        !this.isCustomerDisabled &&
        !this.isCoparentCustomerDisabled &&
        this.customerInit &&
        !this.isCustomerVerified
      ) {
        return 'verifyCustomer'
      }
      // Pending Internal Verification
      if (
        !this.isCustomerDisabled &&
        !this.isCoparentCustomerDisabled &&
        this.isCustomerVerified &&
        !this.isCustomerInternallyVerified
      ) {
        return 'fundingSourceList'
      }
      // Verified but no viewed express pay intro
      if (this.showExpressPayIntro) {
        return 'expressPayIntro'
      }
      // Both parents Verified
      if (
        this.isCustomerInternallyVerified &&
        this.coparentAccountState.verified &&
        !this.isCustomerDisabled &&
        !this.isCoparentCustomerDisabled
      ) {
        return 'activityList'
      }

      return ''
    },
    showNotifyCoparent(): boolean {
      return (
        this.isCustomerPendingInternalVerification &&
        (!this.isCoparentCustomerVerified || !this.isCoparentFundingSourceValid)
      )
    },
    isCoparentPendingInternalVerification(state): boolean {
      return (
        this.isCoparentCustomerVerified &&
        (state.coparentAccountState.hasValidFundingSource || false)
      )
    },
    showPendingApproval(): boolean {
      return (
        (this.isCoparentPendingInternalVerification &&
          this.isCustomerPendingInternalVerification) ||
        false
      )
    },
    getSearchData(): ISearchData | null {
      if (
        this.searchData?.filterValue != '' ||
        this.searchData?.searchText != '' ||
        this.searchData?.sortValue != '' ||
        this.searchData?.dateRange
      ) {
        return this.searchData
      } else {
        return null
      }
    },
    showExpressPayIntro(state): boolean {
      const accountSettings = useAccountSettingsStore()
      return (
        !this.isCustomerDisabled &&
        !this.isCoparentCustomerDisabled &&
        this.isCustomerInternallyVerified &&
        accountSettings.subscriptionT2 &&
        !state.customer?.expressPayIntroLastViewed
      )
    },
    hasPlaidAccountAsDefault: (state) => {
      const _source = state.fundingSources.find(
        (fs) => fs.hasExpressPay && fs.defaultDestination
      )
      return !helpers.isNullEmpty(_source)
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof IMoneyTransferState)[])
      )
    },
    async updateTosAccepted() {
      const source = 'updateTosAccepted'

      try {
        const response = await httpClient.post(
          '/web/api/termsofservice/accepttermsofservice?termsOfServiceId=' +
            this.tosId
        )

        if (!mtHelpers.checkApiError(response, source)) {
          await this.fetchUserAcceptedTos()
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchTos() {
      const source = 'fetchTos'

      try {
        const response = await httpClient.get(
          '/web/api/termsofservice/getlatesttos?tosType=' + TosType.DWOLLA
        )

        if (!mtHelpers.checkApiError(response, source)) {
          this.tosId = response.data.value.itemID
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchUserAcceptedTos() {
      const source = 'fetchUserAcceptedTos'

      try {
        const response = await httpClient.get(
          '/web/api/termsofservice/hasuseracceptedlatest?tosType=' +
            TosType.DWOLLA
        )

        if (!mtHelpers.checkApiError(response, source)) {
          this.tosAccepted = response.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchCustomer() {
      const source = 'fetchCustomer'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getcustomer'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const customer = response.data.value
          this.customer = customer
          this.customerInit = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchCoparentAccountState() {
      const source = 'fetchCoparentAccountState'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getcoparentaccountstate'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const customer = response.data.value
          this.coparentAccountState = customer
          this.coparentAccountStateInit = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchFundingSources() {
      const source = 'fetchFundingSources'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getfundingsources'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const fundingSources = response.data.value
          this.fundingSources = fundingSources
          this.fundingSourcesInit = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchActivityPaged(payload: IFetchActivityPagedPayload) {
      try {
        let baseResponse = `/web/api/MoneyTransfer/GetActivityPaged?PageNumber=${payload.page}&PageSize=${this.pageSize}`
        if (payload.sortDescending != null)
          baseResponse += `&SortDescending=${payload.sortDescending}`
        if (payload.startDate)
          baseResponse += `&StartDate=${payload.startDate.toJSON()}`
        if (payload.endDate)
          baseResponse += `&EndDate=${payload.endDate.toJSON()}`
        if (payload.filterType)
          baseResponse += `&FilterType=${payload.filterType}`
        if (payload.searchTerm)
          baseResponse += `&SearchTerm=${encodeURIComponent(payload.searchTerm)}`

        const response = await httpClient.get(encodeURI(baseResponse))

        if (!response?.data.success) throw new Error(response.data.errorMessage)

        const _activity =
          payload.page == 1
            ? response.data.value
            : [...this.activityList, ...response.data.value]

        this.activityList = _activity
        this.activityListCount = _activity.length
        this.loadMoreActivity = response.data.value.length == this.pageSize
        this.activityListInit = true
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTransfersPaged')
      }
    },
    async fetchActivityById(payload: IFetchActivityByIdPayload) {
      try {
        const url = `/web/api/MoneyTransfer/GetActivityById?Id=${payload.id}&Type=${payload.type}`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data.success) throw new Error(response.data.errorMessage)

        return response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchActivityById')
      }
    },
    async fetchPaymentHistoryById(id: number | null | undefined) {
      if (!id) return

      try {
        const url = `/web/api/MoneyTransfer/GetPaymentHistoryById?ScheduledPaymentId=${id}`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data.success) throw new Error(response.data.errorMessage)

        return response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPaymentHistoryById')
      }
    },
    async fetchUpcomingScheduledPayments() {
      try {
        const url = `/web/api/MoneyTransfer/GetUpcomingScheduledPayments`

        const response = await httpClient.get(encodeURI(url))

        if (!response?.data.success) throw new Error(response.data.errorMessage)

        this.upcomingScheduledPayments = response.data.value

        this.upcomingScheduledPayments.forEach(
          (sp) => (sp.type = ActivityType.SCHEDULEDPAYMENT)
        )
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchUpcomingScheduledPayments')
      }
    },
    async fetchPaymentTotal(transferAmount: string) {
      this.createTransferError = null
      const source = 'fetchPaymentTotal'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getpaymenttotal?transferAmount=' +
            transferAmount
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          const paymentTotal: IGetPaymentTotalResponse = response.data.value

          this.paymentTotal = paymentTotal
        } else {
          if (
            response?.data?.errorCode == ErrorType.VALIDATION_ERROR ||
            response?.data?.errorCode == ErrorType.INVALID_FEE
          ) {
            this.createTransferError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response.data.errorMessage),
              source
            )
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchFundingSourceDependencies(fundingSourceId: number) {
      const source = 'fetchFundingSourceDependencies'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getfundingsourcedependencies?fundingSourceId=' +
            fundingSourceId
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const dependencies = response.data.value
          this.fundingSourceDependencies = dependencies
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchLinkTokenForVerifyMicrodeposits(fundingSourceId: number) {
      const source = 'fetchLinkTokenForVerifyMicrodeposits'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getlinktokenforverifymicrodeposits?fundingSourceId=' +
            fundingSourceId
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const token = response.data.value
          this.linkTokenForMicrodeposit = token.linkToken
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchLinkToken() {
      const source = 'fetchLinkToken'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getlinktoken'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const linkResponse = response.data.value
          this.linkToken = linkResponse?.linkToken
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async createFundingSourceUsingPlaid(
      request: ICreateMoneyTransferFundingSourceUsingPlaidRequest
    ) {
      const source = 'createFundingSourceUsingPlaid'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/createfundingsourceusingplaid',
          request
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          await this.fetchFundingSources()
          this.createFundingSourceError = null
          this.fetchCustomer()
        } else {
          if (
            response?.data?.errorCode ==
              CreateFundingSourceErrorType.DUPLICATE_ACCOUNT_ALL ||
            response?.data?.errorCode ==
              CreateFundingSourceErrorType.DUPLICATE_ACCOUNT_SOME
          ) {
            this.createFundingSourceError = response?.data?.errorMessage
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source,
              true,
              response?.data?.errorMessage
            )
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source
            )
          }
        }

        this.linkToken = null
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async createPlaidErrorLog(
      request: ICreateMoneyTransferPlaidErrorLogRequest
    ) {
      await httpClient.post(
        '/web/api/moneytransfer/createplaiderrorlog',
        request
      )
    },
    async verifyFundingSourceMicroDeposit(request: IVerifyMicroDepositRequest) {
      //you can no longer add a funding source through Dwolla, but it's still possible to verify a legacy (non-Plaid) funding source
      const source = 'verifyFundingSourceMicroDeposit'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/verifyfundingsourcemicrodeposit',
          request
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          const fundingSource = response.data.value
          this.setFundingSource(fundingSource)
          this.verifyFundingSourceMicroDepositError = null
          this.verifyFundingSourceMicroDepositValidationError = null
          this.verifyFundingSourceMicroDepositFailedAttemptError = null

          if (
            fundingSource != null &&
            fundingSource.Status == FundingSourceStatus.VERIFIED
          ) {
            this.fetchCustomer()
          }
        } else {
          const fundingSource = response.data.value
          switch (response?.data?.errorCode) {
            case MicroDepositErrorType.FAILED_ATTEMPT:
              this.setFundingSource(fundingSource)
              this.verifyMicroDepositFundingSource = fundingSource
              this.verifyFundingSourceMicroDepositFailedAttemptError =
                response.data.errorMessage
              break
            case MicroDepositErrorType.VALIDATION_ERROR:
              this.verifyFundingSourceMicroDepositValidationError =
                response.data.errorMessage
              break
            case MicroDepositErrorType.MAX_ATTEMPT_REACHED:
              this.verifyFundingSourceMicroDepositError =
                response.data.errorMessage
              this.setFundingSource(fundingSource)
              break
            default:
              ErrorHelper.handleError(
                new Error(response?.data?.errorMessage),
                source
              )
              break
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    setFundingSource(value: IGetMoneyTransferFundingSourceResponse) {
      const result = this.fundingSources.map((fs) =>
        fs.id === value.id ? value : fs
      )
      this.fundingSources = result
    },
    async createCustomer(customer: ICreateCustomerRequest) {
      this.verifyCustomerError = null
      const source = 'createCustomer'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/createcustomer',
          customer
        )

        if (mtHelpers.checkApiError(response, source, false)) {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.verifyCustomerError = response.data.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source
            )
          }
        }
        return response?.data?.success as boolean
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async retryCustomer(customer: IMoneyTransferCustomerRetryRequest) {
      this.verifyCustomerError = null
      const source = 'retryCustomer'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/retrycustomer',
          customer
        )

        if (mtHelpers.checkApiError(response, source, false)) {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.verifyCustomerError = response.data.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source
            )
          }
        }
        return response?.data?.success as boolean
      } catch (e) {
        ErrorHelper.handleError(e, source)
        return false
      }
    },
    async removeFundingSource(request: IRemoveFundingSourceRequest) {
      this.removeFundingSourceError = null

      const source = 'removeFundingSource'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/removefundingsource',
          request
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          await this.fetchFundingSources()
        } else {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.removeFundingSourceError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source
            )
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }

      this.fundingSourceDependencies = null
    },
    async updateDefaultDestinationFundingSource(
      fundingSource: IUpdateDefaultDestinationFundingSourceRequest
    ) {
      const source = 'updateDefaultDestinationFundingSource'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/updatedefaultdestinationfundingsource',
          fundingSource
        )

        if (!mtHelpers.checkApiError(response, source)) {
          this.fetchFundingSources()
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async addTransfer(transfer: ICreateMoneyTransferRequest): Promise<boolean> {
      this.createTransferError = null
      const source = 'addTransfer'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/createtransfer',
          transfer
        )

        if (mtHelpers.checkApiError(response, source, false)) {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.createTransferError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source,
              true,
              response?.data?.errorCode == ErrorType.INSUFFICIENT_BALANCE
                ? response?.data?.errorMessage
                : null
            )
          }

          return false
        } else return true
      } catch (error) {
        ErrorHelper.handleError(error, source)
        return false
      }
    },
    async addTransferToWallet(transfer: ICreateTransferToWalletRequest) {
      this.createTransferToWalletError = null

      const source = 'addTransferToWallet'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/createtransfertowallet',
          transfer
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          this.fetchCustomer()
        } else {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.createTransferToWalletError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source,
              true
            )
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async addScheduledPayment(
      scheduledPayment: ICreateMoneyTransferScheduledPaymentRequest
    ) {
      this.createTransferError = null
      const source = 'addScheduledPayment'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/createscheduledpayment',
          scheduledPayment
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          return true
        } else {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.createTransferError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source,
              true,
              response?.data?.errorCode == ErrorType.INSUFFICIENT_BALANCE
                ? response?.data?.errorMessage
                : null
            )
          }

          return false
        }
      } catch (error) {
        ErrorHelper.handleError(error, source)
        return false
      }
    },
    async updateScheduledPaymentStatus(
      scheduledPayment: IUpdateMoneyTransferScheduledPaymentStatus
    ) {
      const source = 'updateScheduledPaymentStatus'

      try {
        await httpClient.post(
          '/web/api/moneytransfer/updatescheduledpaymentstatus',
          scheduledPayment
        )
      } catch (e) {
        ErrorHelper.handleError(e, source, true)
      }
    },
    async addTransferRequest(
      transferRequest: ICreateMoneyTransferRequestRequest
    ) {
      const source = 'addTransferRequest'

      try {
        await httpClient.post(
          '/web/api/moneytransfer/createtransferrequest',
          transferRequest
        )
      } catch (e) {
        ErrorHelper.handleError(e, source, true)
      }
    },
    async updateTransferRequest(
      transferRequest: IUpdateMoneyTransferRequestRequest
    ) {
      const source = 'updateTransferRequest'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/updatetransferrequest',
          transferRequest
        )

        if (mtHelpers.checkApiError(response, source)) {
          if (response?.data?.errorCode == ErrorType.VALIDATION_ERROR) {
            this.updateRequestError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source,
              true
            )
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async updateTransferRequestViewed(transferRequest: IIdRequest) {
      const source = 'updateTransferRequestViewed'

      try {
        await httpClient.post(
          '/web/api/moneytransfer/updatetransferrequestviewed',
          transferRequest
        )
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async notifyCoparentNotVerified(): Promise<boolean> {
      const source = 'notifyCoparentNotVerified'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/notifycoparentnotverified'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const notification = response.data.value
          const expiresWhen =
            notification == null ? null : notification.expiresWhen
          this.notifyCoparentLastSent = expiresWhen
          this.notifyCoparentLastSentInit = true

          return true
        } else {
          return false
        }
      } catch (error) {
        ErrorHelper.handleError(error, source, true)
        return false
      }
    },
    async fetchNotifyCoparentLastSent() {
      const source = 'fetchNotifyCoparentLastSent'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getnotifycoparentnotverifiedlastsent'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const notification = response.data.value
          const expiresWhen =
            notification == null ? null : notification.expiresWhen
          this.notifyCoparentLastSent = expiresWhen
          this.notifyCoparentLastSentInit = true
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchRequestReminderLastSent(transferRequest: number) {
      const source = 'fetchRequestReminderLastSent'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/getrequestreminderlastsent?requestId=' +
            transferRequest
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const notification = response.data.value
          const expiresWhen =
            notification == null ? null : notification.expiresWhen
          this.requstReminderLastSent = expiresWhen
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async sendRequestReminder(transferRequest: number) {
      const source = 'sendRequestReminder'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/sendrequestreminder',
          { id: transferRequest }
        )

        if (!mtHelpers.checkApiError(response, source)) {
          const notification = response.data.value
          const expiresWhen =
            notification == null ? null : notification.expiresWhen
          this.requstReminderLastSent = expiresWhen
          return true
        }

        return false
      } catch (e) {
        ErrorHelper.handleError(e, source)
        return false
      }
    },
    async fetchTransferFees() {
      const source = 'fetchTransferFees'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/GetTransferFees'
        )

        if (!mtHelpers.checkApiError(response, source)) {
          this.transferFees = response.data.value
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    getWalletActivity(
      transfer: IActivityListItem,
      dateTimeSettings: IDateTimeSettings
    ): IActivityListItem {
      dateTimeSettings

      return {
        type: ActivityType.WALLETPAYMENT,
        id: transfer.id,
        title: 'You paid a negative balance',
        amount: transfer.amount,
        createdWhen: transfer.createdWhen
        // sortByDate: transfer.lastUpdatedWhen
        //   ? transfer.lastUpdatedWhen
        //   : transfer.createdWhen
      }
    },
    getSinglePaymentActivity(
      transfer: IActivityListItem,
      paidByUser: boolean,
      userInitials: string,
      coParentInitials: string,
      coParentFullName: string,
      dateTimeSettings: IDateTimeSettings
    ) {
      dateTimeSettings

      const descriptionInitials = paidByUser ? userInitials : coParentInitials
      return {
        type: ActivityType.SINGLEPAYMENT,
        id: transfer.id,
        title: mtHelpers.getPaymentTitle(
          paidByUser,
          transfer.status || 0,
          coParentFullName
        ),
        amount: transfer.amount,
        fee: transfer.fee,
        totalAmount: Number(
          Math.round(
            parseFloat((transfer.amount ?? 0) + (transfer.fee ?? 0) + 'e' + 2)
          ) +
            'e-' +
            2
        ),
        status: transfer.status,
        description: transfer.description,
        descriptionInitials: descriptionInitials,
        sending: paidByUser,
        sourceFundingSourceId: transfer.sourceFundingSourceId,
        destinationFundingSourceId: transfer.destinationFundingSourceId,
        moneyTransferRequestId: transfer.moneyTransferRequestId,
        moneyTransferScheduledPaymentId:
          transfer.moneyTransferScheduledPaymentId,
        lastUpdatedWhen: transfer.lastUpdatedWhen,
        createdWhen: transfer.createdWhen,
        showView: true,
        sortByDate: transfer.sortByDate,
        failureDisplay: transfer.failureDisplay,
        clearingType: transfer.clearingType
      } as IActivityListItem
    },
    getSinglePaymentForScheduledPaymentDetails(
      transfer: IActivityListItem,
      paidByUser: boolean,
      dateTimeSettings: IDateTimeSettings
    ): IActivityListItem {
      dateTimeSettings

      const commonStore = useCommonStore()
      return {
        id: transfer.id,
        title: `${mtHelpers.getPaymentStatusString(transfer.status || 0)} 
        ${paidByUser ? ' to' : ' from'} ${
          commonStore.fullUserInfo.coparentFullName
        }`,
        amount: transfer.amount,
        totalAmount: Number(
          Math.round(
            parseFloat((transfer.amount ?? 0) + (transfer.fee ?? 0) + 'e' + 2)
          ) +
            'e-' +
            2
        ),
        fee: transfer.fee,
        status: transfer.status,
        description: transfer.description,
        sending: paidByUser,
        sourceFundingSourceId: transfer.sourceFundingSourceId,
        destinationFundingSourceId: transfer.destinationFundingSourceId,
        moneyTransferRequestId: transfer.moneyTransferRequestId,
        lastUpdatedWhen: transfer.lastUpdatedWhen,
        createdWhen: transfer.createdWhen,
        showView: true,
        failureDisplay: transfer.failureDisplay,
        clearingType: transfer.clearingType
      }
    },
    getScheduledActivity(
      scheduledPayment: IActivityListItem | null | undefined,
      request: IActivityListItem | null | undefined,
      payment: IActivityListItem | null | undefined,
      paidByUser: boolean,
      userInitials: string,
      coParentInitials: string,
      coParentFullName: string,
      dateTimeSettings: IDateTimeSettings
    ): IActivityListItem {
      const descriptionInitials = paidByUser ? userInitials : coParentInitials
      return {
        type: ActivityType.SCHEDULEDPAYMENT,
        id: scheduledPayment?.id,
        title: mtHelpers.getScheduledPaymentTitle(
          paidByUser,
          scheduledPayment?.repeat || false,
          coParentFullName
        ),
        subTitle: mtHelpers.getScheduledPaymentSubTitle(
          scheduledPayment?.repeat || false,
          scheduledPayment?.scheduledWhen || Date(),
          dateTimeSettings
        ),
        relatedActivityTitle: mtHelpers.getRelatedScheduledPaymentActivityTitle(
          paidByUser,
          scheduledPayment?.status || 0,
          scheduledPayment?.repeat || false,
          coParentFullName
        ),
        relatedActivityDescription:
          mtHelpers.getRelatedScheduledPaymentActivityDetails(
            paidByUser,
            scheduledPayment?.status || 0
          ),
        amount: scheduledPayment?.amount,
        fee: scheduledPayment?.fee,
        totalAmount: Number(
          Math.round(
            parseFloat(
              (scheduledPayment?.amount ?? 0) +
                (scheduledPayment?.fee ?? 0) +
                'e' +
                2
            )
          ) +
            'e-' +
            2
        ),
        description: scheduledPayment?.description,
        descriptionInitials: descriptionInitials,
        sending: paidByUser,
        status: scheduledPayment?.status,
        repeat: scheduledPayment?.repeat,
        sourceFundingSourceId:
          scheduledPayment?.sourceFundingSourceId ??
          payment?.sourceFundingSourceId,
        moneyTransferRequestId: scheduledPayment?.moneyTransferRequestId,
        lastUpdatedWhen: scheduledPayment?.lastUpdatedWhen,
        scheduledWhen: scheduledPayment?.scheduledWhen,
        createdWhen: scheduledPayment?.createdWhen,
        hasUpComingPayment: mtHelpers.getHasUpcomingPayment(scheduledPayment),
        payment: payment
          ? ({
              id: payment.id,
              title: mtHelpers.getPaymentTitle(
                paidByUser,
                payment.status || 0,
                coParentFullName
              ),
              description: payment.description,
              amount: payment.amount,
              totalAmount: Number(
                Math.round(
                  parseFloat(
                    (payment.amount ?? 0) + (payment.fee ?? 0) + 'e' + 2
                  )
                ) +
                  'e-' +
                  2
              ),
              fee: payment.fee,
              status: payment.status,
              sending: paidByUser,
              sourceFundingSourceId: payment.sourceFundingSourceId,
              destinationFundingSourceId: payment.destinationFundingSourceId,
              moneyTransferRequestId: payment.moneyTransferRequestId,
              moneyTransferScheduledPaymentId:
                payment.moneyTransferScheduledPaymentId,
              descriptionInitials: descriptionInitials,
              lastUpdatedWhen: payment.lastUpdatedWhen,
              createdWhen: payment.createdWhen,
              showView: false,
              sortByDate: payment?.sortByDate
            } as IActivityListItem)
          : undefined,
        request: request
          ? mtHelpers.getBasicRequest(
              request,
              !paidByUser,
              userInitials,
              coParentInitials,
              coParentFullName,
              dateTimeSettings
            )
          : undefined,
        sortByDate:
          payment?.sortByDate ??
          scheduledPayment?.lastUpdatedWhen ??
          scheduledPayment?.createdWhen,
        clearingType: scheduledPayment?.clearingType ?? payment?.clearingType
      }
    },
    getRequestActivity(
      request: IActivityListItem | null | undefined,
      payment: IActivityListItem | null | undefined,
      scheduledPayment: IActivityListItem | null | undefined,
      userInitials: string,
      coParentInitials: string,
      coParentFullName: string,
      dateTimeSettings: IDateTimeSettings
    ): IActivityListItem {
      const requestedByUser =
        this.customer?.id == request?.destinationCustomerId
      const title = requestedByUser
        ? i18n.global.t('payments.activityList.requestActivity.titleTo', {
            name: coParentFullName
          })
        : i18n.global.t('payments.activityList.requestActivity.titleFrom', {
            name: coParentFullName
          })

      const sendingInitials = requestedByUser ? userInitials : coParentInitials
      const receivingInitials = !requestedByUser
        ? userInitials
        : coParentInitials
      const type = scheduledPayment
        ? ActivityType.REQUEST_SCHEDULEDPAYMENT
        : ActivityType.REQUEST
      const child = payment ? payment : scheduledPayment

      return {
        type: type,
        id: request?.id,
        title: title,
        relatedActivityTitle: mtHelpers.getRelatedRequestActivityTitle(
          requestedByUser,
          request?.status || 0,
          coParentFullName
        ),
        amount: request?.amount,
        status: request?.status,
        description: request?.description,
        descriptionInitials: sendingInitials,
        noteInitials:
          request?.status == RequestStatus.CANCELED
            ? sendingInitials
            : receivingInitials,
        sending: requestedByUser,
        notes: request?.notes,
        lastUpdatedWhen: request?.lastUpdatedWhen,
        viewedWhen: request?.viewedWhen ?? undefined,
        createdWhen: request?.createdWhen,
        destinationFundingSourceId: request?.destinationFundingSourceId,
        payment: payment
          ? ({
              id: payment.id,
              title: mtHelpers.getPaymentTitle(
                !requestedByUser,
                payment.status || 0,
                coParentFullName
              ),
              amount: payment.amount,
              fee: payment.fee,
              totalAmount: Number(
                Math.round(
                  parseFloat(
                    (payment.amount ?? 0) + (payment.fee ?? 0) + 'e' + 2
                  )
                ) +
                  'e-' +
                  2
              ),
              status: payment.status,
              description: payment.description,
              descriptionInitials: receivingInitials,
              sending: !requestedByUser,
              sourceFundingSourceId: payment.sourceFundingSourceId,
              destinationFundingSourceId: payment.destinationFundingSourceId,
              moneyTransferRequestId: payment.moneyTransferRequestId,
              moneyTransferScheduledPaymentId:
                payment.moneyTransferScheduledPaymentId,
              lastUpdatedWhen: payment.lastUpdatedWhen,
              createdWhen: payment.createdWhen,
              showView: false,
              failureDisplay: payment.failureDisplay,
              clearingType: payment.clearingType
            } as unknown as IActivityListItem)
          : undefined,
        scheduledPayment: scheduledPayment
          ? ({
              id: scheduledPayment.id,
              title: mtHelpers.getScheduledPaymentTitle(
                !requestedByUser,
                scheduledPayment.repeat || false,
                coParentFullName
              ),
              subTitle: mtHelpers.getScheduledPaymentSubTitle(
                scheduledPayment.repeat || false,
                scheduledPayment.scheduledWhen || new Date(),
                dateTimeSettings
              ),
              relatedActivityTitle:
                mtHelpers.getRelatedScheduledPaymentActivityTitle(
                  !requestedByUser,
                  scheduledPayment.status || 0,
                  scheduledPayment.repeat || false,
                  coParentFullName
                ),
              relatedActivityDescription:
                mtHelpers.getRelatedScheduledPaymentActivityDetails(
                  !requestedByUser,
                  scheduledPayment.status || 0
                ),
              amount: scheduledPayment.amount,
              fee: scheduledPayment.fee,
              totalAmount: Number(
                Math.round(
                  parseFloat(
                    (scheduledPayment.amount ?? 0) +
                      (scheduledPayment.fee ?? 0) +
                      'e' +
                      2
                  )
                ) +
                  'e-' +
                  2
              ),
              description: scheduledPayment.description,
              descriptionInitials: receivingInitials,
              sending: !requestedByUser,
              status: scheduledPayment.status,
              repeat: scheduledPayment.repeat,
              sourceFundingSourceId: scheduledPayment.sourceFundingSourceId,
              moneyTransferRequestId: scheduledPayment.moneyTransferRequestId,
              lastUpdatedWhen: scheduledPayment.lastUpdatedWhen
                ? scheduledPayment.lastUpdatedWhen
                : null,
              scheduledWhen: mtHelpers.getDateDisplay(
                scheduledPayment.scheduledWhen || new Date(),
                dateTimeSettings,
                false
              ),
              createdWhen: scheduledPayment.createdWhen,
              clearingType: scheduledPayment.clearingType
            } as unknown as IActivityListItem)
          : undefined,
        sortByDate: request?.sortByDate
      }
    },
    setLinkTokenResponse(value: string | null) {
      this.linkToken = value
    },
    setLinkTokenForMicrodepositResponse(value: string | null) {
      this.linkTokenForMicrodeposit = value
    },
    setLinkTokenForUpdateMode(value: string | null) {
      this.linkTokenForUpdateMode = value
    },
    setVerifyMicroDepositPlaidFundingSource(
      value: IGetMoneyTransferFundingSourceResponse | null
    ) {
      this.verifyMicroDepositPlaidFundingSource = value
    },
    setReverifyPlaidFundingSource(
      value: IGetMoneyTransferFundingSourceResponse | null
    ) {
      this.reverifyPlaidFundingSource = value
    },
    setActivityListInit(value: boolean) {
      this.activityListInit = value
    },
    setActivityList(value: IActivityListItem[]) {
      this.activityList = value
    },
    setLoadMoreActivity(value: boolean) {
      this.loadMoreActivity = value
    },
    setCurrentActivity(value: IActivityListItem | null) {
      this.currentActivity = value
    },
    setmoneyTransferDataLoaded(value: boolean) {
      this.moneyTransferDataLoaded = value
    },
    setUpdateRequestError(value: string | null) {
      this.updateRequestError = value
    },
    setSelectedPaymentFundingSource(value: IFundingSource | undefined) {
      this.selectedPaymentFundingSource = value || null
    },
    setSelectedRequestFundingSource(value: IFundingSource | undefined) {
      this.selectedRequestFundingSource = value || null
    },
    setVerifyFundingSourceMicroDepositFailedAttemptError(value: string | null) {
      this.verifyFundingSourceMicroDepositFailedAttemptError = value
    },
    setVerifyMicroDepositFundingSource(
      value: IFundingSource | null | undefined
    ) {
      this.verifyMicroDepositFundingSource = value || null
    },
    setFundingSourceDependencies(
      value: IGetFundingSourceDependenciesResponse | null
    ) {
      this.fundingSourceDependencies = value
    },
    async sortActivityListByDate(sortDesc: boolean) {
      const sortPromise = new Promise((resolve) => {
        const tempActivityList = this.activityList

        tempActivityList.sort(function (a, b) {
          const date1 = a.sortByDate?.valueOf() ?? 0
          const date2 = b.sortByDate?.valueOf() ?? 0

          if (date1 > date2) return sortDesc ? -1 : 1
          if (date1 < date2) return sortDesc ? 1 : -1
          return 0
        })

        resolve(tempActivityList)
      })

      this.activityList = (await sortPromise) as IActivityListItem[]
    },
    getFundingSourceFromId(id: number | undefined): IFundingSource | undefined {
      if (!id) return
      return this.fundingSources.find((fs) => fs.id == id)
    },
    setAndShowMtNotifications(notification: INotification, show: boolean) {
      this.mtNotification = notification
      this.showMtNotification = show
    },
    resetNotifications() {
      this.mtNotification = {
        type: 'warning',
        text: '',
        showCloseButton: false,
        showChevronButton: false
      }
      this.showMtNotification = false
    },
    setVerifyDocumentUploadError(value: string) {
      this.verifyDocumentUploadError = value
    },
    async uploadVerificationDoc(payload: FormData) {
      const url = '/web/api/moneytransfer/uploaddocument'

      try {
        const response = await httpClient.post(url, payload, {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (!response.data.success) {
          this.setVerifyDocumentUploadError(response.data.errorMessage)
          return false
        }

        return true
      } catch (e) {
        if (e == 'Canceled') return
        ErrorHelper.handleError(e, 'saveReply')
      }
    },
    getFundingSourceNameFromId(id: number) {
      return this.fundingSources.find((f) => f.id == id)?.name
    },
    setSearchDataStore(value: ISearchData | null) {
      this.searchData = value
    },
    async setAchNextDayViewed() {
      const source = 'updateExpressPayIntroLastViewed'

      try {
        const response = await httpClient.post(
          `/web/api/moneytransfer/UpdateCustomerExpressPayIntroLastViewed?expressPayIntroLastViewed=${moment
            .utc()
            .toISOString()}`
        )

        if (!mtHelpers.checkApiError(response, source)) {
          if (this.customer) {
            this.customer.expressPayIntroLastViewed = new Date()
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async setFundingSourceIdToReverifyForTestingOnly(fundingSourceId: number) {
      const source = 'setFundingSourceIdToReverifyForTestingOnly'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/SetFundingSourceToBeReVerified?fundingSourceId=' +
            fundingSourceId
        )

        if (!mtHelpers.checkApiError(response, source)) {
          console.log('updated. refresh list')
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async fetchLinkTokenForUpdateMode(fundingSourceId: number) {
      const source = 'fetchLinkTokenForUpdateMode'

      try {
        const response = await httpClient.get(
          '/web/api/moneytransfer/GetLinkTokenForUpdateMode?fundingSourceId=' +
            fundingSourceId
        )

        if (!mtHelpers.checkApiError(response, source)) {
          // set link for updatemode
          const token = response.data.value
          this.linkTokenForUpdateMode = token.linkToken
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    async updateFundingSourceAfterLinkUpdateMode(
      payload: IUpdateFundingSourceAfterUpdateRequest
    ) {
      const source = 'updateFundingSourceAfterLinkUpdateMode'

      try {
        const response = await httpClient.post(
          '/web/api/moneytransfer/updateFundingSourceAfterLinkUpdateMode',
          payload
        )

        if (!mtHelpers.checkApiError(response, source, false)) {
          await this.fetchFundingSources()
          await this.fetchCustomer()
          this.createFundingSourceError = null
        } else {
          if (
            response?.data?.errorCode ==
              CreateFundingSourceErrorType.DUPLICATE_ACCOUNT_ALL ||
            response?.data?.errorCode ==
              CreateFundingSourceErrorType.DUPLICATE_ACCOUNT_SOME
          ) {
            this.createFundingSourceError = response?.data?.errorMessage
          } else {
            ErrorHelper.handleError(
              new Error(response?.data?.errorMessage),
              source
            )
          }
        }
      } catch (e) {
        ErrorHelper.handleError(e, source)
      }
    },
    setUpgradeFundingSourceId(upgradeFundingSourceId: number) {
      this.toUpgradeFundingSourceId = upgradeFundingSourceId
    },
    getFundingSourceNameFromUpgradeId(upgradeId: number) {
      return this.fundingSources.find(
        (f) => f.upgradeFundingSourceId == upgradeId
      )?.name
    },
    async handleNewPaymentRequestNotification() {
      //wait a second before calling for requests
      setTimeout(() => {
        this.setNewTransfarRequest(true)
      }, 500)
    },
    setRedirecting(redirecting: boolean) {
      this.redirecting = redirecting
    },
    setNewTransfarRequest(val: boolean) {
      this.newTransferRequest = val
    }
  }
})
