﻿<template>
  <div class="calendar-notification">
    <button
      type="button"
      class="btn circle"
      :data-notify="
        getNumberOfUnseenEvents > 0 ? getNumberOfUnseenEvents : null
      "
      @click="toggleNotifications()"
      ref="notifRef"
    >
      <font-awesome-icon icon="fa-light fa-bell" class="icon" />
      <span class="sr-only">{{ t('calendar.notifications.title') }}</span>
    </button>
    <div
      v-if="state.showNotifications && isDesktopWidth"
      class="notification menu-dropdown"
    >
      <div
        class="notification-header flex align-center"
      >
        <!-- <button v-if="isMobileWidth" class="btn circle dialog-close inline-flex flex-grow-0" @click="toggleNotifications()">
            <font-awesome-icon
              icon="fa-light fa-xmark"
              class="icon-size-1"
              style="color: var(--gray-6)"
            />
            <span class="sr-only">close</span>
        </button> -->
        <header class="inline-flex flex-1 font-size-0">{{ t('calendar.notifications.title') }}</header>
      </div>

      <hr
        style="
          width: auto;
          margin: 0 -0.5rem 0 -0.5rem;
          padding: 0px;
          border-color: var(--border-color);
          border-bottom: 1px;
        "
      />

      <span class="empty-dropdown" v-if="getNumberOfUnseenEvents == 0">
        {{ t('calendar.notifications.empty') }}
      </span>
      <ul class="dropdown-body">
        <li
          v-for="event in getUnseenEvents"
          :key="event.itemID"
          @click="selectNewEvent(event.itemID)"
        >
          <!-- new event -->
          <div
            class="notification-content font-size-1"
            v-if="
              event.lastEdited == null &&
              !event.isCanceled &&
              event.parentItemID == null
            "
          >
            <i18n-t
              keypath="calendar.notifications.eventAdded"
              tag="span"
            >
              <template #type>
                <strong>{{ t('calendar.notifications.type.new')}}</strong>
              </template>
              <template #user>
                {{ fullUserInfo.coparentFirstName }}
              </template>
            </i18n-t>
            <span class="font-size-0 gray-light">{{
              formatDateFromNow(event.createdWhen)
            }}</span>
          </div>
          <!-- event edited / no exceptions -->
          <div
            class="notification-content font-size-1"
            v-if="
              event.lastEdited != null &&
              !event.isCanceled &&
              event.parentItemID == null
            "
          >
            <i18n-t
              keypath="calendar.notifications.eventModified"
              tag="span"
            >
              <template #type>
                <strong>{{ t('calendar.notifications.type.edited')}}</strong>
              </template>
              <template #user>
                {{ fullUserInfo.coparentFirstName }}
              </template>
            </i18n-t>
            <span class="font-size-0 gray-light">{{
              formatDateFromNow(event.lastEdited)
            }}</span>
          </div>
          <!-- exception created or exception edited after creation -->
          <div
            class="notification-content font-size-1"
            v-if="!event.isCanceled && event.parentItemID != null"
          >
            <i18n-t
              keypath="calendar.notifications.eventModified"
              tag="span"
            >
              <template #type>
                <strong>{{ t('calendar.notifications.type.edited')}}</strong>
              </template>
              <template #user>
                {{ fullUserInfo.coparentFirstName }}
              </template>
            </i18n-t>
            <span class="font-size-0 gray-light">{{
              formatDateFromNow(
                event.lastEdited ? event.lastEdited : event.createdWhen
              )
            }}</span>
          </div>
          <!-- canceled event -->
          <div class="notification-content font-size-1" v-if="event.isCanceled">
            <i18n-t
              keypath="calendar.notifications.eventModified"
              tag="span"
            >
              <template #type>
                <strong>{{ t('calendar.notifications.type.canceled')}}</strong>
              </template>
              <template #user>
                {{ fullUserInfo.coparentFirstName }}
              </template>
            </i18n-t>
            <span class="font-size-0 gray-light">{{
              formatDateFromNow(event.createdWhen)
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <teleport to="body">
      <mobile-modal
        :modal-active="state.showNotifications && isMobileWidth"
        :show-footer="false"
        title="Notifications"
      >
        <template #content>
          <span class="no-notifications" v-if="getNumberOfUnseenEvents == 0">
            {{ t('calendar.notifications.empty') }}
          </span>
          <ul class="mobile-body">
            <li
              v-for="event in getUnseenEvents"
              :key="event.itemID"
              @click="selectNewEvent(event.itemID)"
            >
              <!-- new event -->
              <div
                class="mobile-content font-size-1"
                v-if="
                  event.lastEdited == null &&
                  !event.isCanceled &&
                  event.parentItemID == null
                "
              >
                <i18n-t
                  keypath="calendar.notifications.eventAdded"
                  tag="span"
                >
                  <template #type>
                    <strong>{{ t('calendar.notifications.type.new')}}</strong>
                  </template>
                  <template #user>
                    {{ fullUserInfo.coparentFirstName }}
                  </template>
                </i18n-t>
                <span class="font-size-0 gray-light">{{
                  formatDateFromNow(event.createdWhen)
                }}</span>
              </div>
              <!-- event edited / no exceptions -->
              <div
                class="mobile-content font-size-1"
                v-if="
                  event.lastEdited != null &&
                  !event.isCanceled &&
                  event.parentItemID == null
                "
              >
                <i18n-t
                  keypath="calendar.notifications.eventModified"
                  tag="span"
                >
                  <template #type>
                    <strong>{{ t('calendar.notifications.type.edited')}}</strong>
                  </template>
                  <template #user>
                    {{ fullUserInfo.coparentFirstName }}
                  </template>
                </i18n-t>
                <span class="font-size-0 gray-light">{{
                  formatDateFromNow(event.lastEdited)
                }}</span>
              </div>
              <!-- exception created or exception edited after creation -->
              <div
                class="notification-content font-size-1"
                v-if="!event.isCanceled && event.parentItemID != null"
              >
                <i18n-t
                  keypath="calendar.notifications.eventModified"
                  tag="span"
                >
                  <template #type>
                    <strong>{{ t('calendar.notifications.type.edited')}}</strong>
                  </template>
                  <template #user>
                    {{ fullUserInfo.coparentFirstName }}
                  </template>
                </i18n-t>
                <span class="font-size-0 gray-light">{{
                  formatDateFromNow(
                    event.lastEdited ? event.lastEdited : event.createdWhen
                  )
                }}</span>
              </div>
              <!-- canceled event -->
              <div class="mobile-content font-size-1" v-if="event.isCanceled">
                <i18n-t
                  keypath="calendar.notifications.eventModified"
                  tag="span"
                >
                  <template #type>
                    <strong>{{ t('calendar.notifications.type.canceled')}}</strong>
                  </template>
                  <template #user>
                    {{ fullUserInfo.coparentFirstName }}
                  </template>
                </i18n-t>
                <span class="font-size-0 gray-light">{{
                  formatDateFromNow(event.createdWhen)
                }}</span>
              </div>
            </li>
          </ul>
        </template>
      </mobile-modal>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import constants from '@/exports/constants'
import { reactive, watch, onMounted, ref, computed } from 'vue'
import { useI18n, I18nT } from 'vue-i18n'
import { useCalendarStore } from '@/stores/CalendarStore'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import mobileModal from '@/components/ModalV2Component.vue'
import type { ICalendarEntry } from '@/models/interfaces'
import { onClickOutside } from '@vueuse/core'
const { t } = useI18n({ useScope: 'global' })

const calendarStore = useCalendarStore()
const commonStore = useCommonStore()

const { isMobileWidth, isDesktopWidth, fullUserInfo } = storeToRefs(commonStore)

const { unseenEvents, viewType, getNumberOfUnseenEvents } =
  storeToRefs(calendarStore)

const { formatDateFromNow, fetchBadgeCounts } = commonStore

const { getNewEntry, setEntry, setShowEventDetails } = calendarStore

const state = reactive({
  today: new Date(),
  constants: constants,
  showNotifications: false,
  activeView: false,
  showCalendarSettings: false,
  showNewEventsMenu: false,
  viewTypeLocal: constants.viewTypes.month,
  showCalMenu: false,
  showInfoModal: false
})

const notifRef = ref(null)

onMounted(() => {
  //setViewType() route.name
  state.viewTypeLocal = viewType.value
})

// sort events
const getUnseenEvents = computed(() => {
  const sortedUnseenEvents = unseenEvents.value.slice().reverse()

  sortedUnseenEvents.sort((a: ICalendarEntry, b: ICalendarEntry) => {
    const dateA = getEventSortByDate(a) ?? new Date()
    const dateB = getEventSortByDate(b) ?? new Date()
    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
  })

  return sortedUnseenEvents
})

watch(viewType, async (newVal, oldVal) => {
  if (newVal != oldVal) {
    state.viewTypeLocal = newVal
  }
})

function getEventSortByDate(event: ICalendarEntry) {
  // canceled event
  if (event.isCanceled) {
    return event.createdWhen
    // occurence of recurring
  } else if (event.parentItemID) {
    return event.lastEdited ? event.lastEdited : event.createdWhen
    // regular event edit
  } else if (event.lastEdited) {
    return event.lastEdited
    // new event
  } else {
    return event.createdWhen
  }
}

function selectNewEvent(itemId: number) {
  getNewEntry(itemId).then((entry: ICalendarEntry | undefined) => {
    if (entry) {
      setEntry(entry)
      setShowEventDetails(true)
      fetchBadgeCounts()
    }
  })
}

onClickOutside(notifRef, () => {
  if (state.showNotifications)
    state.showNotifications = !state.showNotifications
})

function toggleNotifications() {
  state.showNotifications = !state.showNotifications
}
</script>
<style scoped lang="scss">

.menuMobilestyleObject {
  left: -105px;
}

.icon {
  width: 21px;
  height: 25px;
  color: var(--brand);
  @media (min-width: 48em) {
    width: var(--size-2);
    height: var(--size-2);
    color: var(--white);
  }
}

.menu-dropdown {
  inset-inline-end: 0;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-height: 315px;
  header {
    border-bottom: 0px;
    padding-block: 0;
  }
}
.notification-header {
  padding: var(--size-00) var(--size-0);
  text-align: center;
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-2);
  border-bottom: 0px;

  @media (min-width: 48em) {
    text-align: left;
    padding: 0 0 var(--size-00) 0;
    border-bottom: 0;
    font-weight: var(--font-weight-2);
    font-variation-settings: 'wght' 500;
    font-size: var(--font-size-00);
  }

  &::after {
    display: inline-flex;
    content: '';
    flex-grow: 0;
    width: var(--size-1);
    height: var(--size-1);
  }
}

.calendar-notification {
  --border-color: light-dark(var(--gray-3), var(--gray-7));
  z-index: 800;
  display: flex;
  svg {
    color: var(--brand);
  }

  @media (min-width: 48em) {
    svg {
      color: white;
    }
    top: 30px;
  }

  .notification.menu-dropdown {
    @media (min-width: 48em) {
      inset-block-start: calc(100% + 0.75rem);
      inset-inline-end: -5rem;
      border-bottom-left-radius: var(--radius-3);
      border-bottom-right-radius: var(--radius-3);
      position: absolute;
      padding: var(--size-0) var(--size-1) var(--size-1) var(--size-1);
      bottom: auto;
    }
    .notification-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      line-height: 1.5ch;
      > * {
        white-space: nowrap;
      }
      strong {
        --base-weight: 600;
      }
    }
    .dropdown-body > * {
      display: flex;
      align-items: baseline;
      padding: 0.75rem 2rem 0.5rem 0.5rem;
      margin-left: -0.5rem;
      margin-right: -0.5rem;

      &:last-child {
        padding-bottom: 0.5rem;
        margin-bottom: -0.5rem;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
        border-image: linear-gradient(
            to right,
            transparent 30px,
            var(--border-color) 0%
          )
          100% 1;
      }

      &::before {
        content: '';
        display: inline-flex;
        width: 6px;
        height: 6px;
        background-color: var(--magenta);
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}

.mobile-content {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  line-height: 1.5ch;
  > * {
    white-space: nowrap;
  }
  height: 20%;
  strong {
    --base-weight: 600;
  }
}
.mobile-body {
  overflow-x: clip;
  overflow-y: auto;
  overscroll-behavior: contain;
}
.mobile-body > * {
  display: flex;
  gap: 0.25rem;
  align-items: baseline;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  padding-block: 0.75rem 0.5rem;
  padding-inline: 0.5rem 1rem;
  margin-left: 0;
  margin-right: -0.5rem;
  margin-inline: 0 -0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
    border-image: linear-gradient(to right, transparent 40px, var(--border-color) 0%)
      100% 1;
  }

  &::before {
    content: '';
    display: inline-flex;
    width: 6px;
    height: 6px;
    background-color: var(--magenta);
    border-radius: 50%;
    margin-left: 0.5rem;
    margin-right: 1rem;
    margin-inline: 0.5rem 1rem;
  }
}

span {
  display: block;
}

.empty-dropdown {
  padding-block-start: 1rem;
  font-size: 1rem;
  width: fit-content;
  white-space: nowrap;
}
.no-notifications {
  padding-block: 2rem;
  padding-inline-start: 1rem;
}
li {
  cursor: pointer;
  &:hover {
    background: var(--surface-2);
    border-radius: var(--radius-1);
    &:last-child {
      border-bottom-right-radius: var(--radius-3);
      border-bottom-left-radius: var(--radius-3);
    }
  }
}

:deep(.modal-inner) {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
